.container{
  padding: 20px 40px;
 
}

.header{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  .ant-select{
    width: 200px;
  }
}